<template>
  <div>
    <v-snackbar
      v-for="(notification, index) in notifications"
      :key="index"
      :top="true"
      :right="true"
      :color="notification.type"
      v-model="notification.show"
      :style="{ top: 8 + index * 56 + 'px' }"
      :timeout="notification.timeout"
    >
      <span :class="{ 'black--text': notification.type === 'warning' }">{{
        notification.text
      }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useNotificationStore } from '@/stores/notification'

export default {
  computed: {
    ...mapState(useNotificationStore, ['notifications']),
  },
}

</script>

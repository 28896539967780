<template>
  <v-app>
    <side-navigation :expanded="drawer" :items="sideNavigation"></side-navigation>

    <!-- Toolbar -->
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <v-fade-transition mode="out-in">
        <!-- NOTE: The scroll behaviour depends on the transition duration! -->
        <router-view />
      </v-fade-transition>
    </v-main>

    <the-notifications />

    <app-footer />
    <slot name="afterFooter"></slot>
    <update-notification />
  </v-app>
</template>

<script>
import UpdateNotification from '@/components/pwa/UpdateNotification'
import SideNavigation from '@/components/SideNavigation'
import AppFooter from '@/components/Footer'
import TheNotifications from './TheNotifications.vue'


export default {
  name: 'ApplicationLayout',
  components: { UpdateNotification, SideNavigation, AppFooter, TheNotifications },
  props: {
    title: {
      type: String,
      default: 'Tourenverwaltung',
    },
    sideNavigation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: false,
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndUp
  },
}
</script>

<template>
  <application-layout
    title="SAC-Tourenverwaltung: E-Mails"
    :sideNavigation="sideNavigation"
  ></application-layout>
</template>

<script>
import { handleApiError } from '@/utils.js'

import { NAVIGATION } from './constants'
import ApplicationLayout from '@/components/ApplicationLayout.vue'
import { useUserStore } from '@/stores/user'

export default {
  name: 'Main',
  components: { ApplicationLayout },
  props: {},
  data() {
    return {}
  },
  computed: {
    sideNavigation() {
      return [
        { type: 'item', title: 'Tourenverwaltung', icon: 'mdi-arrow-left', props: { to: { name: 'Dashboard' }, exact: true } },
        { type: 'item', title: 'Übersicht', icon: 'mdi-view-dashboard', props: { to: { name: 'MailDashboard' }, exact: true } },
        ...NAVIGATION.map(({ title, icon, route }) => {
          return { type: 'item', title, icon, props: { to: route, exact: true } }
        }),
      ]
    },
  },
  mounted() {
    useUserStore().fetchUser().catch(e => handleApiError(e, 'Fehler beim Laden der Benutzerdaten'))
  },
}
</script>

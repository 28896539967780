
const NAVIGATION = [
  {
    title: 'Neue Nachricht',
    icon: 'mdi-email-plus',
    route: { name: 'MailCreate' },
  },
  {
    title: 'Mailing Listen',
    icon: 'mdi-contacts',
    route: { name: 'MailLists' },
  },
  {
    title: 'Statistik',
    icon: 'mdi-chart-timeline-variant',
    route: { name: 'MailStatistics' },
  },
]

export {
  NAVIGATION,
}
